import React from "react"
import Card from "./eventCard"

export default function blogList({ events }) {
  return (
    <>
      <div className="blog-list">
        {events.map(({ node: event }) => {
          console.log(event)

          return <Card key={event.id} data={event} />
        })}
      </div>
      <div className="rss-feed-container">
        <a href="/event-rss.xml" target="__blank">
          <button
          style={{fontSize:"2rem"}}
            className="btn btn--contained  btn--small "
            // data-sal="slide-up"
            // data-sal-delay="200"
            // data-sal-duration="700"
          >
            RSS Feed
          </button>
        </a>
      </div>
    </>
  )
}
