import { graphql } from "gatsby"
import React from "react"
import EventList from "../components/event/eventList"
import Layout from "../components/layout"

export const query = graphql`
  query Events {
    allStrapiEvent(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          image {
            publicURL
          }
          date(formatString: "dddd MMMM Do", locale: "Asia/Mumbai")
          Title
          Content
          strapiId
        }
      }
    }
  }
`

export default ({
  data: {
    allStrapiEvent: { edges: events },
  },
}) => {
  // const firstArticle = articles[0].node
  // const restArticles = articles.filter((article, index) => index > 0)
  return (
    <Layout navClass="navigation--blue">
      {/* <div className="blog-header">
        <h1>Blog</h1>
        <h3>Latest blogs on dispute resolution</h3>
      </div> */}
      <div className="u-padding-top-xl"></div>
      <React.Fragment>
        {events.length > 0 && (
          <React.Fragment>
            <EventList events={events} />
          </React.Fragment>
        )}
      </React.Fragment>
    </Layout>
  )
}
